<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <h4>{{ $t('backoffice.settings.subtitle-links') }}</h4>
      <div class="d-flex">
        <b-button variant="primary" @click="addLinkCouple()">
          <feather-icon
            icon="PlusCircleIcon"
            class="text-white"
          />
        </b-button>
        <b-button 
          variant="primary" 
          class="ml-1" 
          @click="saveLinks()"
        >
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>

    <!-- Website -->
    <b-form-group v-if="websiteEnabled">
      <label for="website" class="font-weight-bold"> {{ $t('backoffice.settings.website') }}</label>
      <b-form-input
        id="website"
        v-model="website"
        type="text"
        :state="isValidWeb"
        :placeholder="$t('form.type-placeholder')"
        @input="validatorWeb(website)"
      />
    </b-form-group>

    <!-- First link input empty -->
    <div v-if="isFirstLinkSectionVisible" class="d-flex flex-wrap">
      <!-- Type -->
      <b-form-group>
        <label for="link-type" class="font-weight-bold"> {{ $t("backoffice.settings.type") }}</label>
        <v-select
          id="link-type"
          v-model="currentLink.label"
          :searchable="false"
          :placeholder="$t('form.select-placeholder')"
          :options="linkLabelsOptions"
          :clearable="false"
          style="min-width: 200px"
        />
      </b-form-group>
      <!-- Name -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="link-name"
          v-model="currentLink.name"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Link -->
      <b-form-group class="ml-md-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.link-label") }}</label>
        <b-form-input
          id="link-name"
          v-model="currentLink.url"
          type="url"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </div>

    <!-- All links list full but editable -->
    <div v-for="(link, index) in links" :key="index" class="d-flex flex-wrap align-items-center">
      <!-- Type -->
      <b-form-group>
        <label for="link-type" class="font-weight-bold"> {{ $t("backoffice.settings.type") }}</label>
        <v-select
          id="link-type"
          v-model="links[index].label"
          :searchable="false"
          :placeholder="$t('form.select-placeholder')"
          :options="linkLabelsOptions"
          :clearable="false"
          style="min-width: 200px"
        />
      </b-form-group>
      <!-- Name -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="link-name"
          v-model="links[index].name"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Link -->
      <b-form-group class="ml-md-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.link-label") }}</label>
        <b-form-input
          id="link-name"
          v-model="links[index].url"
          type="url"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <feather-icon
        icon="Trash2Icon"
        size="16"
        class="ml-50 text-primary pointer"
        @click="removeItem(index)"
      />
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import { validatorUrlValidator } from '@/@core/utils/validations/validators';

export default {
  name: 'Links',
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentLink: {
        label: 'Website',
        name: null,
        url: null,
      },
      communityWeb: '',
      links: [],
      isValid: null,
      isValidWeb: null,
      isFirstLinkSectionVisible: false,
      website: '',
      isRemoving: false,
      websiteEnabled: false
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    linkLabelsOptions() {
      return ActiveLinksLabels;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  watch: {
    communityWeb(value) {
      return value;
    },
  },
  created() {
    this.links = this.currentCollective.links || [];
    this.communityWeb = this.currentCollective.landing;
    if (this.links?.length > 0) {
      this.isFirstLinkSectionVisible = false;
    } else {
      this.isFirstLinkSectionVisible = true;
    }
    this.website = this.communityWeb;
  },
  methods: {
    addLinkCouple() {
      if(!this.links){
        this.links = [];
      }
      if (this.currentLink.url && this.currentLink.url !== null && validatorUrlValidator(this.currentLink.url)) {
        this.links.push(this.currentLink);
        this.currentLink = {
          label: 'Website',
          name: null,
          url: null,
        };
        this.isValid = null;
      } else if (this.isRemoving) {
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstLinkSectionVisible = true;
    },
    async saveLinks() {
      this.$emit('website', this.website);
      this.addLinkCouple();
      this.links = this.links.filter(({ url }) => url !== '');
      this.links?.length > 0 ? this.isFirstLinkSectionVisible = false : true;
      this.currentLink = {};
      const urls = this.links?.map(({ url, label, name }) => ({ url, label, name }));
      if (urls?.length > 0 || this.isRemoving) {
        try {
          await this.$store.dispatch('addSettings', {
            links: urls,
            joinURL: this.joinURL,
            registrationURL: this.registrationURL,
          });
          // this.website === this.communityWeb ? this.notifySuccess(this.$t('backoffice.settings.messages.success')) : '';
        } catch {
          this.notifyError(this.$t('backoffice.settings.messages.error'));
        }
      }
      this.isRemoving = false;
    },
    validator(item) {
      this.isValid = validatorUrlValidator(item);
    },
    validatorWeb(item) {
      this.isValidWeb = validatorUrlValidator(item);
    },
    removeItem(index) {
      this.links.splice(index, 1);
      this.isRemoving = true;
      if (this.links?.length <= 0) {
        this.addLinkCouple();
      }
    },
  },
};
</script>
