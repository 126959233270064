<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <h4>{{ $t('backoffice.settings.subtitle-links') }}</h4>
      <div class="d-flex">
        <b-button variant="primary" @click="addLinkCouple()">
          <feather-icon
            icon="PlusCircleIcon"
            class="text-white"
          />
        </b-button>
        <b-button variant="primary" class="ml-1" @click="saveLinks()">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>
    <div v-if="isFirstLinkSectionVisible" class="d-flex flex-wrap">
      <!-- Type -->
      <b-form-group>
        <label for="link-type" class="font-weight-bold"> {{ $t("backoffice.settings.type") }}</label>
        <v-select
          id="link-type"
          v-model="currentLink.label"
          :searchable="false"
          :placeholder="$t('form.select-placeholder')"
          :options="linkLabelsOptions"
          :clearable="false"
          style="min-width: 200px"
        />
      </b-form-group>
      <!-- Name -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="link-name"
          v-model="currentLink.name"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Link -->
      <b-form-group class="ml-md-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.link-label") }}</label>
        <b-form-input
          id="link-name"
          v-model="currentLink.url"
          type="url"
          :state="isValid"
          @input="validator(currentLink.url)"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </div>
    <!-- All links -->
    <div v-for="(link, index) in links" :key="index" class="d-flex flex-wrap">
      <!-- Type -->
      <b-form-group>
        <label for="link-type" class="font-weight-bold"> {{ $t("backoffice.settings.type") }}</label>
        <v-select
          id="link-type"
          v-model="links[index].label"
          :searchable="false"
          :placeholder="$t('form.select-placeholder')"
          :options="linkLabelsOptions"
          :clearable="false"
          style="min-width: 200px"
        />
      </b-form-group>
      <!-- Name -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="link-name"
          v-model="links[index].name"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Link -->
      <b-form-group class="ml-md-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.link-label") }}</label>
        <b-form-input
          id="link-name"
          v-model="links[index].url"
          type="url"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import { validatorUrlValidator } from '@/@core/utils/validations/validators';

export default {
  name: 'Links',
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    profileLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentLink: {
        label: 'Website',
        name: null,
        url: null,
      },
      links: [],
      isValid: null,
      isFirstLinkSectionVisible: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    linkLabelsOptions() {
      return ActiveLinksLabels;
    },
    isSaveEnabled() {
      return this.form.name && this.form.slug && (this.form.accessibilityAux || this.form.accessibility) && (this.form.visibilityAux || this.form.visibility);
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  mounted() {
    this.links = this.profileLinks.map((item) => item);
    if (this.links.length > 0) {
      this.isFirstLinkSectionVisible = false;
    } else {
      this.isFirstLinkSectionVisible = true;
    }
  },
  watch: {
    profileLinks() {
      this.links = this.profileLinks.map((item) => item);
      if (this.links.length > 0) {
        this.isFirstLinkSectionVisible = false;
      } else {
        this.isFirstLinkSectionVisible = true;
      }
    },
  },
  methods: {
    addLinkCouple() {
      if (this.currentLink.url && this.currentLink.url !== null && validatorUrlValidator(this.currentLink.url)) {
        this.links.push(this.currentLink);
        this.currentLink = {
          label: 'Website',
          name: null,
          url: null,
        };
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstLinkSectionVisible = true;
    },
    async saveLinks() {
      this.addLinkCouple();
      this.links = this.links.filter(({ url }) => url !== '');
      this.links.length > 0 ? this.isFirstLinkSectionVisible = false : true;
      this.currentLink = {};
      if (this.links.length > 0) {
        this.$emit('save-links', this.links);
      } else {
        this.notifyError(this.$t('backoffice.settings.messages.error'));
      }
    },
    validator(item) {
      this.isValid = validatorUrlValidator(item);
    },
  },
};
</script>
